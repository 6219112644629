/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* General  */
body
{
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

html, body {
  height: 100%;
}

.shadowboxTitle {
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 25px;
}

.shadowbox {
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding-top: 16px;

  margin-bottom: 25px;
}

.headingMT {
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.text-right {
  text-align: right;
}

.matFlex {
  display: flex;
  flex-direction: column;
}

.bg-horeca-log {
  height: 100vh;
  background: url('assets/img/bg-login-horeca.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bg-gradient-1 {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
  background: linear-gradient(#FFFFFF, #EAEAEA);
}

.half-img {
  width: 50%;
  height: 50%;
}

.bg-color-1 {
  background: #F44E4E;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}



/* ---------------------------------------------------
    GLOBAL TITLES
----------------------------------------------------- */
/* Navbar Titles  */
h4, span.routerHeading, a.routerHeading {
  font-family: 'Urbane', sans-serif;
  font-weight: bold;
  font-size: 1.4em;
  color: #fff;
  font-style: normal;
}

/* Router Titles  */
ul li {
  margin: 1vh 0vh;
}

h5 {
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
}

h1 {
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
}

h1.heading {
  padding-top: 10px;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #fff;
}

h3.heading {
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
}

h5.heading {
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #1D2E48;
  text-align: left;
  border-bottom: 1px solid #1D2E48;
  padding-bottom: 3px;
  margin-bottom: 20px;
}

/* ---------------------------------------------------
    GLOBAL TEXT
----------------------------------------------------- */
/* Error message color  */
mat-error {
  color: #F46250 !important;
}

/* Contracts Text */
span.contractsHeaderInfo {  /* Contract header: information headers */
  color: #F46250;
  font-size: 0.8em;
  font-weight: 400;
}

span.heading {  /* Contract people: people information headers */
  font-size: 1em;
  font-weight: 400;
  font-family: 'Urbane', sans-serif;
  color: #F46250;
}

span.orangeText {
  color: #F46250;
  font-size: 0.8em;
  font-weight: 400;
}

/* Text White  */
.txtWhite {
  color: #fff;
}

/* Text Align  */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* ---------------------------------------------------
    GLOBAL BUTTONS, TOGGLE, CHECK-BOXES & RADIO BUTTONS
----------------------------------------------------- */
/* Buttons with btn-danger Class */
.btn-danger {
  background: #1D2E48;
  font-weight: 700;
  border: none;
}

.btn-danger:hover {
  background: #F46250;
}

/* Mat-flat button  */
a.mat-flat-button, 
div.pl-1 a.mat-flat-button,
div.pl-3 a.mat-flat-button,
div button.mat-flat-button,
div.pr-1 button.mat-flat-button,
div.col-2 button.mat-flat-button,
div.mb-1 button.mat-flat-button,
td button.mat-flat-button  {
  background: #1D2E48;
  color: white;
  font-family: 'Urbane', sans-serif;
  border-radius: 25px;
}

a.mat-flat-button:hover,
div.pl-1 a.mat-flat-button:hover,
div.pl-3 a.mat-flat-button:hover,
div button.mat-flat-button:hover,
div.pr-1 button.mat-flat-button:enabled:hover,
div.col-2 button.mat-flat-button:hover,
div.mb-1 button.mat-flat-button:hover,
td button.mat-flat-button:hover  {
  background: #F46250;
  color: white;
}


/* Orange buttons */
div button.orangeButton,
div.pr-1 button.orangeButton
div.mb-1 button.orangeButton
{
  background: #F46250 !important;
  color: white;
  border-radius: 25px;
  font-family: 'Urbane', sans-serif;
}

/* Orange buttons */
div button.orangeButton:hover,
div.pr-1 button.orangeButton:hover
div.mb-1 button.orangeButton:hover
{
  background: #1D2E48 !important;
}

/* Check-box */
div.mb-3 mat-checkbox
{
  font-family: 'Open Sans', sans-serif;
}

.mat-checkbox-checked .mat-checkbox-background
{
  background-color: #F46250 !important;
  font-family: 'Open Sans', sans-serif;
}

/* Radio buttons */
mat-radio-button,
div.form-row mat-label.green-i-1
{
  font-family: 'Open Sans', sans-serif;
  color: #1D2E48;
}

mat-radio-button.mat-radio-checked .mat-radio-outer-circle
{
  background: white !important;
  border: 1px solid #1D2E48 !important;
}

mat-radio-button.mat-radio-checked .mat-radio-inner-circle
{
  background: #F46250 !important;
}


/* ---------------------------------------------------
    GLOBAL BADGE, CHIP, etc.
----------------------------------------------------- */
div.headingMT mat-chip.chipNumber,
div.mt-3 mat-chip.chipNumber,
div.mb-3 mat-chip.chipNumber,
div.col-12 mat-chip.chipNumber,
mat-card .mat-badge-content
{
  background: #F46250;
  color: white;
}

span .mat-badge-content {
  background: #F46250;
}

/* ---------------------------------------------------
    GLOBAL FORMS (Input, Mat-form-field, Mat-select)
----------------------------------------------------- */
/* Label in Form's input */
mat-label,
mat-form-field.costCenter-chip-list .mat-form-field-label
{
  color: #1D2E48 !important;
}

/* Label if focused in Form's input */
.mat-form-field.mat-focused mat-label {
  color: #4258 !important;
}

/* Label if error in Form's input */
.mat-form-field-invalid mat-label,
.mat-form-field-invalid .mat-form-field-required-marker, /* "*" invalid marker */ 
.mat-form-field-invalid.mat-focused mat-label
{
  color: #F46250 !important;
}

/* Insertion point color in Form's input */
mat-form-field input {
  caret-color: #F46250  !important;
}

/* Mandatory fields (*) in Form's input */
.mat-form-field-label {
  color: #F46250 !important;
}

/* Colorized borders for Form's input */
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #1D2E48;
  opacity: 0.6 !important;
}

/* Colorized borders for Form's input when focused */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
  color: #4258 !important;
  opacity: 1 !important;
}

/* Colorized border when input error */
.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick{
  color: #F46250 !important;
  opacity: 0.8 !important;
}

/* Colorized underline when focused on an input */
.mat-form-field-ripple
{
  background: #F46250 !important;
 }

/* Mat-select colorized labels & arrow  */
.mat-option.mat-selected .mat-option-text, .mat-select-arrow-wrapper .mat-select-arrow {
  color: #F46250 !important;
}



/* ------------------------------------------------------------------     OLD   ------------------------------------------------------------------------------------- */

/* h1, h2 {
  color:  #1D2E48;
}

h4 {
  font-family: 'Ubuntu', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.4em;
  line-height: 35px;
  color: #fff;
  padding-top: 5px;
}

h2.heading {
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  color: #1D2E48;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

h3.heading {
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  color:  #1D2E48;
}

h5.heading {
  font-size: 1.2em;
  font-weight: 400;
  font-family: 'Ubuntu', sans-serif;
  color: #1D2E48;
  text-align: left;
  border-bottom: 1px solid #F44E4E;
  padding-bottom: 3px;
  margin-bottom: 20px;
} */