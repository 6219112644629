@use '@angular/material' as mat;

@include mat.core();

$my-app-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
$my-app-accent: mat.define-palette(mat.$blue_gray-palette, 50, 100, 400);
$my-app-warn: mat.define-palette(mat.$red-palette, A400);
$my-app-theme: mat.define-light-theme(
    $my-app-primary,
    $my-app-accent,
    $my-app-warn
);

@include mat.all-component-themes($my-app-theme);

.alternate-theme {
  $alternate-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
  $alternate-accent: mat.define-palette(mat.$amber-palette);
  $alternate-warn: mat.define-palette(mat.$red-palette);
  $alternate-theme: mat.define-light-theme(
      $alternate-primary,
      $alternate-accent,
      $alternate-warn
  );
  @include mat.all-component-themes($alternate-theme);
}

.third-theme {
  $third-primary: mat.define-palette(mat.$cyan-palette, 600, 700, 800);
  $third-accent: mat.define-palette(mat.$orange-palette, 500);
  $third-warn: mat.define-palette(mat.$red-palette, A400);
  $third-theme: mat.define-light-theme(
      $third-primary,
      $third-accent,
      $third-warn
  );
  @include mat.all-component-themes($third-theme);
}


/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.customSnackValid {
  color: #4aa0b5;
  background-color: #d8f4fb;
  border-style: solid;
  border-width: 1px;
  border-color: #4aa0b5;
}

.customSnackValid .mat-simple-snackbar-action {
  color: #4aa0b5;
}

.customSnackInvalid {
  color: #e25a55;
  background-color: #fdccca;
  border-style: solid;
  border-width: 1px;
  border-color: #e25a55;
}

.customSnackInvalid .mat-simple-snackbar-action {
  color: #e25a55;
}

.customSnackError {
  color: #f3a53b;
  background-color: #fce8ce;
  border-style: solid;
  border-width: 1px;
  border-color: #f3a53b;
}

.customSnackError .mat-simple-snackbar-action {
  color: #f3a53b;
}


td.mat-cell {
  padding: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.table {
  width: 100%;
  max-width: 100%;

  th,
  td {
    vertical-align: middle;
    text-align: center;
  }

  thead th {
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

}


table .okStatut {
  border-left: 3px solid #26c84c;;
}

table .busyStatut {
  border-left: 3px solid #FF9D00;
}

table .middleStatut {
  border-left: 3px solid #ffa200;
}

table .inactiveStatut {
  border-left: 3px solid #F44E4E;
}

table .validate {
  border-left: 3px solid #26c84c;;
}

table .toValidate {
  border-left: 3px solid #F44E4E;
}

.green {
  color: #26c84c;
}

.orange {
  color: #FF9D00;
}

.red {
  color: #F44E4E;
}

.sick {
  color: #00a4e0;
}

table .busyColor {
  color: #FF9D00;
  font-weight: 700;
}

table .activeColor {
  color: #26c84c;
  font-weight: 700;
}

table .inactiveColor {
  color: #F44E4E;
  font-weight: 700;
}


table .middleColor {
  color: #ffa200;
  font-weight: 700;
}

.inactiveTable {
  background: linear-gradient(to right, rgba(244, 78, 78, 0.25), rgba(244, 78, 78, 0), rgba(244, 78, 78, 0.25));
}

.mxs-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.matFlex {
  display: flex;
  flex-direction: column;
}

.matFlex > * {
  width: 100%;
}

.matCardMapHeaderImage {
  background-image: url('/../../assets/img/GoogleMaps.jpg');
  background-size: cover;
}

.mat-form-field-empty.mat-form-field-label {
  color: #d0d0d0;
}

.headingMT {
  margin-top: 1.2em;
}

.buttonNMT {
  margin-top: -15px;
}

.tabsMT {
  margin-top: 1em;
}

.txtAreaH-5 {
  height: 5em;
}

.txtAreaH-18 {
  height: 18em;
}

.txtAreaH-23 {
  height: 23.6em;
}

.mat-form-field-subscript-wrapper {
  font-size: 65%;
  font-style: italic;
  margin-top: 0.33333334em;
  margin-bottom: 0.33333334em;
  top: calc(100% - 1.79166667em);
}

.mat-panel-customer-padding .mat-expansion-panel-body {
  padding: 14px 14px 24px 14px;
}

.mat-panel-costcenter-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-panel-worker-padding .mat-expansion-panel-body {
  padding: 0;
}

.mat-panel-day-padding .mat-expansion-panel-body {
  padding: 0;
}

.listLink:hover {
  cursor: pointer;
  background-color: rgba(202, 202, 202, 0.1);
}

.mat-form-field.no-padding > .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}
